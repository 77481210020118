const config = {
  title: 'mihaiblaga.dev',
  description: 'The adventures of a JavaScripter in a world filled with tasks.',
  coverImage: 'img/blog-cover.jpg',
  github: 'https://github.com/mihaiblaga89',
  linkedIn: 'https://www.linkedin.com/in/mihai-blaga/',
  lang: 'en',
  siteUrl: 'https://www.mihaiblaga.dev',
  facebook: 'https://www.facebook.com/legolas8911',
  // twitter: 'https://twitter.com/blaga_mihai',
}

export default config
